var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-overlay',{attrs:{"center":"","show":_vm.isLoading,"rounded":"sm"},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('div',{staticClass:"text-center mt-5"},[_c('base-icon',{attrs:{"name":"loading","width":"35","height":"35"}}),_vm._v(" "),_c('p',{attrs:{"id":"cancel-label"}},[_vm._v("Operazione in corso...")])],1)]},proxy:true}])},[(!_vm.isLoading)?_c('div',{staticClass:"mx-1"},[_c('h6',[_vm._v("Titoli da incassare")]),_vm._v(" "),_c('export-options',{attrs:{"exportUrl":_vm.exportUrl,"repository":_vm.repository,"resource":_vm.resource,"tableRef":_vm.$refs[_vm.tableRef],"filter":_vm.filter,"options":[
      {
        code: 'EXPOTICLI',
        label: null,
        formats: ['csv', 'pdf'],
      },
    ]}}),_vm._v(" "),_c('insurance-ancillaries',{ref:"InsuranceAncillaryDetailTableRef1",attrs:{"infomodalName":"T1","fields":_vm.fields,"repository":_vm.repository,"resource":_vm.resource,"filterName":_vm.filterName1,"onlyActions":['infomodal'],"noInnerWidth":"","sortField":"delay_until","filterOn":{
          byRelations: ['byBroker', 'byBookEntry', 'byInsurerParticipant'],
        },"includeRowSelector":"","noInputPaginator":""},on:{"rowSelector":_vm.onAddBookEntry}}),_vm._v(" "),_c('h6',[_vm._v("Altri Titoli")]),_vm._v(" "),_c('export-options',{attrs:{"exportUrl":_vm.exportUrl,"repository":_vm.repository,"resource":_vm.resource,"tableRef":_vm.$refs[_vm.tableRef],"filter":_vm.filter,"options":[
      {
        code: 'EXPOATCLI',
        label: null,
        formats: ['csv', 'pdf'],
      },
    ]}}),_vm._v(" "),_c('insurance-ancillaries',{ref:"InsuranceAncillaryDetailTableRef2",attrs:{"infomodalName":"T2","fields":_vm.fields,"repository":_vm.repository,"resource":_vm.resource,"filterName":_vm.filterName2,"onlyActions":['infomodal'],"noInnerWidth":"","sortField":"delay_until","filterOn":{
          byRelations: ['byBroker', 'byBookEntry', 'byInsurerParticipant'],
        },"includeRowSelector":"","noInputPaginator":""},on:{"rowSelector":_vm.onAddBookEntry}})],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }