var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mx-1"},[_c('export-options',{attrs:{"exportUrl":_vm.exportUrl,"repository":_vm.repository,"resource":_vm.resource,"tableRef":_vm.$refs[_vm.tableRef],"filter":_vm.filter,"options":[
      {
        code: 'EXPOSINPOL',
        label: null,
        formats: ['csv', 'pdf'],
      },
    ]}}),_vm._v(" "),_c('table-claims',{ref:_vm.tableRef,attrs:{"fields":_vm.fields,"repository":_vm.repository,"resource":_vm.resource,"filterName":_vm.filterName,"noActions":"","noInnerWidth":"","noInputPaginator":""}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }