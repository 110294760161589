<template>
  <div>
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading" class="mx-1">
        <export-options
            :exportUrl="exportUrl"
            :repository="repository"
            :resource="resource"
            :tableRef="$refs[tableRef]"
            :filter="filter"
            :options="[
        {
          code: 'EXPOINPOL',
          label: null,
          formats: ['csv', 'pdf'],
        },
      ]"
        ></export-options>
        <book-entry
          :fields="fields"
          :repository="repository"
          :resource="resource"
          :filterName="filterName"
          :ref="tableRef"
          noActions
          noInnerWidth
          noInputPaginator
          noPagination
          includeBottomRow
          sortField="book_date"
          :descending="true"
          :filterOn="{
            byRelations: [
              'byBroker',
              'byTask',
              'byInsurer',
              'byInsuranceAncillary',
              'byVariousAccounting',
              'byRegistry',
            ],
          }"
        ></book-entry>
      </div>
      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import { toLocaleCurrency } from "@/utils/strings";
import { toLocaleDate } from "@/utils/dates";
import ExportOptions from "@/components/ExportOptions";
import BaseIcon from "@/components/BaseIcon";
import BookEntry from "@/components/tables/BookEntries";
import DetailTabAutofetchTableMixin from "@/mixins/DetailTabAutofetchTableMixin";

export default {
  mixins: [DetailTabAutofetchTableMixin],
  name: "Accounting",
  props: {
    resourceId: Number,
  },
  data() {
    return {
      exportUrl: null,
      filter: this.initFilter(),
      isLoading: false,
      insuranceAncillariesIds: [],
      tableRef: "BookEntriesDetailTableRef",
      filterName: "filterBookEntriesDetail",
      resource: "book_entries",
      repository: "book_entry",
      fields: [
        {
          key: "book_date",
          label: this.getDictionary("book_date", "book_entry"),
          sortable: true,
          sortKey: "book_date",
          formatter: (value) => toLocaleDate(value),
        },
        {
          key: "effective_date",
          label: this.getDictionary("effective_date", "book_entry"),
          sortable: true,
          sortKey: "effective_date",
          formatter: (value) => toLocaleDate(value),
        },
        {
          key: "insurer.formatted_title",
          label: this.getDictionary("title", "insurer"),
        },
        {
          key: "insurance_policy_number",
          label: this.getDictionary("insurance_policy_number", "book_entry"),
        },
        {
          key: "branch",
          label: this.getDictionary("title", "risk_branch"),
          sortable: false,
        },
        {
          key: "product",
          label: this.getDictionary("insurance_risk"),
          sortable: false,
        },
        {
          key: "document_code",
          label: this.getDictionary("code", "book_tag"),
        },
        {
          key: "customer",
          label: this.getDictionary("registry"),
          sortable: false,
        },
        {
          key: "gross",
          label: this.getDictionary("gross", "book_entry"),
          sortable: true,
          sortKey: "gross",
          formatter: (value) => toLocaleCurrency(value),
          thClass: "text-right",
          thStyle: {
            "text-align": "right",
          },
          tdClass: "text-right",
        },
        {
          key: "agency_gross",
          label: this.getDictionary("Provv. Agenzia"),
          sortable: false,
          thClass: "text-right",
          thStyle: {
            "text-align": "right",
          },
          tdClass: "text-right",
        },
        {
          key: "saler_gross",
          label: this.getDictionary("Provv. Produttore"),
          sortable: false,
          thClass: "text-right",
          thStyle: {
            "text-align": "right",
          },
          tdClass: "text-right",
        },
        {
          key: "broker",
          label: this.getDictionary("broker", "book_entry"),
        },
      ],
    };
  },
  methods: {
    initFilter() {
      let init = {
        byInsurancePolicy: { id: this.resourceId },
      };
      return init;
    },
  },
  components: {
    BaseIcon,
    BookEntry,
    ExportOptions,
  },
};
</script>
