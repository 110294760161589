<template>
  <div class="mt-1">
    <div class="mb-1">
      <b-row>
        <b-col align="right">
          <b-button
            class="btn-create"
            type="button"
            variant="primary"
            size="sm"
            @click="openAddRelationModal"
            title="Associa relazione all'anagrafica"
            ><b-icon icon="plus-circle" aria-hidden="true"></b-icon> Aggiungi
            relazione</b-button
          >
        </b-col>
      </b-row>
    </div>
    <registry-relations
      noCustom
      noPagination
      hasChecks
      :fields="fields"
      repository="registry"
      resource="registries"
      :filterName="filterName"
      :ref="tableRef"
      noInnerWidth
      @destroy="onDelete"
      :resourceId="resourceId"
      :dbRowData="dbRowData"
      :onlyActions="['destroy']"
    ></registry-relations>
    <add-relation-modal
      :fields="fields"
      :resourceId="resourceId"
      :excludedRegistryIds="excludedRegistryIds"
      @saveRelation="onAdd"
    ></add-relation-modal>
  </div>
</template>

<script>
import FormMixin from "@/mixins/FormMixin";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import AddRelationModal from "@/components/modals/addRelationModal";
import DetailTabAutofetchTableMixin from "@/mixins/DetailTabAutofetchTableMixin";
import RegistryRelations from "@/components/tables/RegistryRelations";
import StorageGetterMixin from "@/mixins/StorageGetterMixin";
//import { computed } from "vue";

export default {
  name: "Relations",
  mixins: [FormMixin, DetailTabAutofetchTableMixin, StorageGetterMixin],
  props: {
    resourceId: Number,
    dbRowData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      tableRef: "RelativeTypesRelationTable",
      filterName: "filterRelativeTypesEdit",
      repository: "registry",
      excludedRegistryIds: [this.resourceId],
      excludedRelativeIds: [],
      fields: [
        {
          key: "customer",
          label: this.getDictionary("customer_name"),
        },
        {
          key: "relation",
          label: this.getDictionary("relation", "registry"),
        },
        {
          key: "address",
          label: this.getDictionary("attribute_ADDR_value", "correspondence"),
        },
      ],
    };
  },
  // provide() {
  //   // use function syntax so that we can access `this`
  //   return {
  //     args: computed(() => this.args),
  //   };
  // },
  components: {
    AddRelationModal,
    RegistryRelations,
  },
  methods: {
    onAdd(val) {
      let payload = {
        [val.id]: {
          relative_type_id: val.relation_type_id,
        },
      };
      this.addToGroup(payload)
        .then(() => {
          this.$bvModal.hide("addRelationModal");
          this.excludedRegistryIds = [this.resourceId];
          this.$showSnackbar({
            preset: "success",
            text: "Relazione associata con successo",
          });
          this.$refs[this.tableRef].fetch(this.args).finally(() => {});
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        });
    },
    addToGroup(payload) {
      const Repo = RepositoryFactory.get(this.repository);
      return Repo.pivot_store(this.resourceId, "registry", {
        registry: payload,
      });
    },
    updateRemoveFromRegistry(payload) {
      const Repo = RepositoryFactory.get(this.repository);
      return Repo.pivot_update(this.resourceId, "registry", {
        registry: payload,
      });
    },
    removeFromRegistry(registryIds) {
      const Repo = RepositoryFactory.get(this.repository);
      return Repo.pivot_destroy(this.resourceId, "registry", {
        registry: registryIds,
      });
    },
    onDelete(id) {
      if (
        typeof id !== "undefined" &&
        id !== null &&
        this.$refs[this.tableRef] !== undefined
      ) {
        let promises = [];
        let registryIds = [];
        registryIds.push(id);
        let items = this.$refs[this.tableRef].items;
        if (registryIds.length) {
          let payload = {};
          let filteredItems = items.filter(
            (item) => !registryIds.includes(item.id)
          );
          filteredItems.forEach((item) => {
            payload[item.id] = {
              relative_type_id: item.pivot.relative_type.id,
            };
          });
          if (registryIds.length === items.length) {
            promises.push(this.removeFromRegistry(registryIds));
          } else {
            promises.push(this.updateRemoveFromRegistry(payload));
          }
          Promise.all(promises)
            .then(() => {
              this.$showSnackbar({
                preset: "success",
                text: `Aggiornamento avvenuto con successo`,
              });
              // this.$refs[this.tableRef].fetch();
              this.$refs[this.tableRef].fetch(this.args).finally(() => {});
            })
            .catch((error) => {
              let errMsg = this.$getErrorMessage(error);
              this.$showSnackbar({
                preset: "error",
                text: `${errMsg}`,
              });
            });
        } else {
          this.$showSnackbar({
            preset: "success",
            text: `Nessun Record Da Aggiornare`,
          });
        }
      }
    },
    openAddRelationModal() {
      if (this.$refs[this.tableRef] !== undefined) {
        this.excludedRegistryIds = [this.resourceId];
        this.$refs[this.tableRef].items.forEach((item) =>
          this.excludedRegistryIds.push(item.id)
        );
        this.$bvModal.show("addRelationModal");
      }
    },
    registryMapping(data) {
      return data.registries ? data.registries : [];
    },
  },
  mounted() {},
  beforeMount() {
    console.log("beforeMount Relations");
    const path = "auth/";
    const resources = [];
    if (!this.hasLoadedResource("relative_types"))
      resources.push(
        this.$store
          .dispatch(`${path}relative_types`)
          .then(() => {})
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
          })
          .finally(() => {
            this.unloadMsg();
            this.isLoading = false;
          })
      );
    if (resources.length)
      this.getResources(resources)
        .then(() => {
          this.isLoading = false;
        })
        .catch((error) => {
          console.log("One or more promises crashed... :|");
          this.onFailure(error);
        });
    this.isLoading = false;
    this.args = {
      items: { handle: this.registryMapping },
      verb: {
        name: "show",
        params: [this.resourceId, "relations/byRegistry"],
      },
    };
  },
  computed: {
    rep() {
      return this.repository;
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.table-responsive, .b-table-sticky-header) {
  /* overflow: auto;  unset or auto value is not ok */
  overflow: inherit;
  max-height: 500px !important; /* prevent external container to scroll*/
  min-height: 100px !important;
}

:deep(.card-text .row .form-group) {
  margin-bottom: 0rem !important;
}
</style>
